
.agreements{
    .agreement:not(:last-child){
        margin-bottom: 20px;
    }
}
.agreement-box{
    background-color: #fafafa;
    border: 1px solid #ececec;
    border-radius: 6px;
    padding: 20px;
    height: 300px;
    overflow-y: auto;
    overflow-x: visible;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .agreements{
        .agreement:not(:last-child){
            margin-bottom: 40px;
        }
    }
}
@media (min-width:1200px){
}

